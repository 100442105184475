import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { HttpClient } from "../../utils/HttpClient";

const EditProperty = () => {
  const { propertyId } = useParams();
  const [property, setProperty] = useState(null);
  const navigate = useNavigate();
  // Fetch property data
  useEffect(() => {
    const httpClient = new HttpClient();
    const fetchProperty = async () => {
      try {
        const response = await httpClient.get(`/properties/${propertyId}`);
        setProperty(response.data);
      } catch (error) {
        console.error("Error fetching property:", error);
      }
    };

    fetchProperty();
  }, [propertyId]);

  const handleSave = async (e) => {
    e.preventDefault();

    const httpClient = new HttpClient();
    const formData = new FormData();

    // Add non-file fields to formData
    for (const key in property) {
      if (key !== "images") {
        formData.append(key, property[key]);
      }
    }

    // Add image files to formData
    if (property.images && property.images.length > 0) {
      property.images.forEach((file, index) => {
        formData.append(`images[${index}]`, file);
      });
    }

    try {
      await httpClient.patch(`/properties/${propertyId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Important for file upload
        },
      });
      navigate("/properties");
    } catch (error) {
      console.error("Error saving property:", error);
    }
  };

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProperty((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle image change with file size validation
  const handleImageChange = (e) => {
    const files = e.target.files;
    const maxSize = 1 * 1024 * 1024; // 1MB
    const validFiles = Array.from(files).filter((file) => file.size <= maxSize);

    if (validFiles.length !== files.length) {
      alert("Some files are larger than 1MB and were not added.");
    }

    setProperty((prevState) => ({
      ...prevState,
      images: validFiles,
    }));
  };

  // Checkbox rendering function
  const renderCheckbox = (label, name) => (
    <div className="w3-third">
      <label>
        <input
          type="checkbox"
          name={name}
          checked={!!property[name]}
          onChange={(e) =>
            setProperty((prevState) => ({
              ...prevState,
              [name]: e.target.checked,
            }))
          }
        />
        {label}
      </label>
    </div>
  );

  if (!property) return <div>Loading...</div>;

  // Form input fields generation
  const renderInput = (label, name, type = "text", placeholder = "") => (
    <div className="w3-third">
      <label>{label}</label>
      <input
        className="w3-input"
        type={type}
        name={name}
        value={property[name] || ""}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  );

  const renderSelect = (label, name, value, options) => (
    <div className="w3-third">
      <label>{label}</label>
      <select
        className="w3-select"
        name={name}
        value={value}
        onChange={handleChange}
      >
        <option value="">Select</option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );

  return (
    <div>
      <h1>Edit {property.property_name}</h1>
      <form onSubmit={handleSave} className="w3-container">
        <div className="w3-section infor-category--container">
          <h3>Basic Information</h3>
          <div className="w3-row-padding">
            {renderInput("Seller or Buyer Name", "name")}
            {renderInput("Property Name", "property_name")}
          </div>
          <div className="w3-row-padding">
            {renderInput("Price", "price")}
            {renderInput("Monthly Rates", "monthly_rates", "text", "%")}
            {renderInput("Monthly Levy", "monthly_levy")}
          </div>
          <div className="w3-row-padding">
            {renderInput("Land Size", "land_size")}
            {renderSelect(
              "Property Type",
              "property_type",
              property.property_type,
              ["sale", "rent", "buy"]
            )}
            {renderSelect("Category", "category", property.category, [
              "house",
              "apartment/flat",
              "farm",
              "vacant land/plot",
              "townhouse",
              "industrial property",
              "commercial property",
            ])}
          </div>
          <div className="w3-row-padding">
            <label>Property Description</label>
            <textarea
              className="w3-input"
              name="description"
              value={property.description}
              onChange={handleChange}
              placeholder="Enter property details, press Enter for a new line"
              rows="5"
            ></textarea>
          </div>
        </div>

        {/* Property Features */}
        <div className="w3-section infor-category--container">
          <h3>Property Features</h3>
          <div className="w3-row-padding">
            {renderInput("Bedrooms", "bedrooms")}
            {renderInput("Bathrooms", "bathrooms")}
            {renderInput("Kitchens", "kitchens")}
          </div>
          <div className="w3-row-padding">
            {renderInput("Dining Rooms", "dining_rooms")}
            {renderInput("Sitting Rooms", "sitting_rooms")}
            {renderInput("Toilets", "toilets")}
          </div>
        </div>

        {/* Backyard Features */}
        <div className="w3-section infor-category--container">
          <h3>Backyard Property Features</h3>
          <div className="w3-row-padding">
            {renderCheckbox("Outside Building", "outside_building")}
            {renderCheckbox("Flatlet", "flatlet")}
            {renderCheckbox("Kitchenette", "kitchenette")}
            {renderCheckbox("Parking", "parking")}
          </div>
        </div>

        {/* Location Details */}
        <div className="w3-section infor-category--container">
          <h3>Location Details</h3>
          <div className="w3-row-padding">
            {renderInput("House Number", "house_number")}
            {renderInput("Street Name", "street_name")}
            {renderInput("Region", "region")}
          </div>
          <div className="w3-row-padding">
            {renderInput("Town", "town")}
            {renderInput("Suburb", "suburb")}
          </div>
        </div>

        <div className="w3-section">
          <h3>Upload Images</h3>
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>

        <button className="w3-button w3-green" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default EditProperty;
