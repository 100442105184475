import React, { useState, useEffect } from "react";
import "../styles/Navbar.css";
import { IoMdMenu } from "react-icons/io";
import logo from "../assets/images/logo3.png";
import { HttpClient } from "../utils/HttpClient";

const Navbar = ({ toggleSidebar }) => {
  const [username, setUsername] = useState("");

  useEffect(() => {
    const httpClient = new HttpClient();

    httpClient
      .get("/me")
      .then((response) => {
        console.log("Fetched user data:", response.data); // Logging user data
        if (response.data && response.data.name) {
          setUsername(response.data.name); // Accessing the name from response.data
        }
      })
      .catch((error) => console.error("Error fetching user data:", error));
  }, []);

  return (
    <nav className="navbar">
      <div className="logo-search-container">
        <div className="navbar-logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="navbar-search">
          <input type="text" placeholder="Search for results..." />
        </div>
      </div>

      <div className="navbar-icons">
        <i className="icon-menu" onClick={toggleSidebar}>
          <IoMdMenu />
        </i>

        <i className="icon-profile">
          <p>Welcome, {username || "Guest"}</p>
        </i>
      </div>
    </nav>
  );
};

export default Navbar;
